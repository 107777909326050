import { render, staticRenderFns } from "./Videos.vue?vue&type=template&id=29a0af56&scoped=true"
import script from "./Videos.vue?vue&type=script&lang=js"
export * from "./Videos.vue?vue&type=script&lang=js"
import style0 from "./Videos.vue?vue&type=style&index=0&id=29a0af56&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_css-loader@6.11.0_webpack@5.97.1__lodash@4._c079d9bc466cc5c9236cd4794a1dd5d8/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29a0af56",
  null
  
)

export default component.exports